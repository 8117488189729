<template>
  <div class="early-box" :style="{ padding: padW + 'px' }"
    ref="early-box">
    <vue-seamless-scroll :data="dataList"
       :style="{ width: '100%',height: boxH+'px', overflow: 'hidden' }"
      :class-option="defaultOption">
      <div class="area"
        v-for="(item, index) in dataList"
        :key="index">
        <div class="progress-label">
          <span>{{ item.label }}</span>
          <span><i>{{ item.value || 0 }}</i> 条</span>
        </div>
        <div>
          <Progress :percentage="item.progressValue"
            color="#9DFEFF"
            :showText="false"
            :conterHeight="4"
            :isRadius="false"
            stroke-linecap="square"
            :proTextStyle="proTextStyle"
            :outerStyle="outerStyle"
            isImage
            imgUrl="https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/data-p1.png"
            :perStyle="{borderRadius: 0}"
            :stroke-width="20" />
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import Progress from '@/components/Progress';
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  components: {
    Progress,
    vueSeamlessScroll
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.$nextTick(() => {
      this.boxH = this.$refs['early-box'].offsetHeight * 0.88;
      this.padW = this.$refs['early-box'].offsetHeight * 0.06;
    });
  },
  watch: {
    options: {
      handler(v) {
        this.dataList = this.options.map(val => ({
          progressValue: val.acRatio,
          label: val.alarmName,
          value: val.ac
        }));
      this.$nextTick(() => {
         this.boxH = this.$refs['early-box'].offsetHeight * 0.88;
         this.padW = this.$refs['early-box'].offsetHeight * 0.06;
      });
      }
    }
  },
  data() {
    return {
      dataList: [],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      proTextStyle: { color: '#F7981D', fontSize: '0.26rem' },
      outerStyle: { border: '1px solid rgba(128, 235, 255, .5)', borderRadius: 4 + 'px', height: 16 + 'px' },
      boxH: 0,
      padW: 0
    };
  }
}
</script>

<style lang="scss" scoped>
.early-box {
  height: 89.2%;
//   padding: 6%;
  overflow: hidden;
  .area {
    // display: flex;
    // // padding: 12px 30px;
    // align-items: center;
    // padding: 2% 1%;
    margin-bottom: 10px;
    width: 100%;
    > span {
      color: #fff;
      font-size: 0.2rem;
      margin-right: 0.08rem;
    }
    > div {
      flex: 1;
    }
    > .progress-label {
      display: flex;
      justify-content: space-between;
      margin-right: 6px;
      font-size: 14px;
      color: #fff;
      i {
        font-size: 16px;
        color: rgba(247, 152, 29, 1);
      }
    }
  }
}
</style>