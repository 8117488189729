<template>
  <div class="model-box">
    <div class="basic-box">
      <div class="basic-box-item">品牌：{{ options.brand || '--' }}</div>
      <div class="basic-box-item">出厂日期：{{ options.productionDate || '--' }}</div>
      <div class="basic-box-item">设备购置日期：{{ options.purchaseDate || '--' }}</div>
      <div class="basic-box-item">设备购置价值：{{ options.purchasePrice || '--' }}</div>
      <div class="basic-box-item">是否融资：{{ options.isFinancing || '--' }}</div>
      <div class="basic-box-item">区域地点：{{ options.address || '--' }}</div>
      <div class="basic-box-item">设备工况：{{ options.deviceWork || '--' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: ()=>{}
    }
  },
}
</script>

<style lang="scss" scoped>
.model-box {
  height: 84%;
  padding: 4%;
  font-size: 0.14rem;
  color: #FFFFFF;
  .basic-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      width: 49%;
      margin-bottom: 0.14rem;
    }
  }
}
</style>