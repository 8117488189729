<template>
  <div class="model-box">
    <div class="information">
      <div class="information-left">
        <div class="information-left-text">理赔案件数量</div>
        <div class="information-left-num">
          {{ options.claimsCount || "--" }} <span>起</span>
        </div>
      </div>
      <div class="information-right">
        <div class="information-right-text">赔偿金额</div>
        <div class="information-right-num">
          {{ options.claimsAmount || "--" }} <span>元</span>
        </div>
      </div>
    </div>
    <div style="height: calc(100% - 0.84rem)" ref="service-bar">
      <Chart :option="pieOptions" style="height: 100%" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initCharts(v.dangerList);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      pieOptions: {},
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      this.$nextTick(() => {
        // const clientWidth = this.$refs['distributionBox'].offsetWidth / 2;
        // const clientHeight = this.$refs['distributionBox'].offsetHeight / 2;
        // this.itemWidth = clientWidth > clientHeight ? clientHeight : clientWidth;
        // this.boxH = this.$refs["model-box"].offsetHeight;
        this.initCharts(v.dangerList);
      });
      // })
    },
    getSize(res, type) {
      const clientWidth = this.$refs["service-bar"].offsetWidth;
      const clientHeight = this.$refs["service-bar"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data = []) {
      var scale = this.getSize(0.3, 2);
      let echartData = data.map((val) => ({
        name: val.dangerType,
        value: val.dangerPercentage,
      }));
      var rich = {
        yellow: {
          color: "#ffc72b",
          fontSize: scale * 2.6,
          fontFamily: "ZhanKuQingKeHuangYouTi",
          align: "center",
        },
        total: {
          color: "#ffc72b",
          fontSize: scale * 2.2,
          align: "center",
        },
        white: {
          color: "#fff",
          align: "center",
          fontSize: scale * 2.2,
          padding: [1, 0],
        },
        blue: {
          color: "#49dff0",
          fontSize: 2.2 * scale,
          align: "center",
        },
        hr: {
          //  borderColor: '#0b5263',
          backgroundColor: "t",
          borderRadius: 3,
          width: 2,
          height: 2,
          padding: [4, 4, 0, -12],
        },
      };
      this.pieOptions = {
        
        legend: {
          top: "center",
          textStyle: {
            color: "#fff",
            fontSize: scale * 2.2,
          },
          "orient": "vertical",
          itemWidth: scale * 2.2,
          itemHeight: scale * 2.2,
          right: scale * 1.2,
          textStyle: {
            rich: {
              name: {
                width: scale * 2.2,
                color: "#fff",
              },
            },
          },
          formatter: function (params) {
            return `{name|${params}}`;
          },
          data: echartData.map(val=> val.name),
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["0%", "66%"],
		        center: ["35%", "50%"],
            color: [
              "#116ec9",
              "#770bd8",
              "#d0c512",
              "#1caddb",
              "#128aff",
              "#6f81da",
              "#00ffb4",
            ],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "outside",
                  color: "#ddd",
                  formatter: function (params, ticket, callback) {
                    return (
                      "{white|" +
                      params.name +
                      "}\n{hr|}\n{yellow|" +
                      params.value +
                      "% }"
                    );
                  },
                  rich: rich,
                },
                labelLine: {
                  show: true,
                  type: "dashed",
                  length: scale * 2,
                  length2: scale * 3,
                  color: "#00ffff",
                },
              },
            },
            data: echartData,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 89%;
  padding: 2%;
  .information {
    display: flex;
    justify-content: space-between;
    height: 36%;
    padding: 0 2%;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      height: 100%;
      padding: 0.16rem;
      background-image: url("../../../../assets/images/bigScreen/customerRecord/quantity.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        font-size: 0.14rem;
        color: #fff;
      }
      &-num {
        font-size: 0.22rem;
        color: #18d2ce;
        font-family: "ZhanKuQingKeHuangYouTi";
        span {
          font-family: auto;
          font-size: 0.14rem;
          color: #fff;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      height: 100%;
      padding: 0.16rem;
      background-image: url("../../../../assets/images/bigScreen/customerRecord/quantity.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        font-size: 0.14rem;
        color: #fff;
      }
      &-num {
        font-size: 0.22rem;
        color: #1560ea;
        font-family: "ZhanKuQingKeHuangYouTi";
        span {
          font-family: auto;
          font-size: 0.14rem;
          color: #fff;
        }
      }
    }
  }
}
</style>