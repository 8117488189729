<template>
  <div class="screen-user" ref="model-box" v-loading="isLoading">
    <Chart
      style="height: 100%; min-height: 100px"
      ref="chartBox"
      :option="lineOptions"
    ></Chart>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
import { getCompanyNameDetails } from "@/api/BigScreenDisplay";
export default {
  components: { Chart },
  props: {
    year: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    year: {
      handler(v) {
        this.init();
      },
      deep: true,
    },
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initChats(v || []);
        });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      lineOptions: {},
      quantityNum: "",
      typeList: ["登录数"],
      isLoading: false,
    };
  },
  created() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  methods: {
    init() {
      this.isLoading = true;
      getCompanyNameDetails({ year: this.year })
        .then((res) => {
          this.isLoading = false;
          this.initChats(res.data);
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initChats(dataList = []) {
      const sizeHNum = this.getSize(0.3, 2);
      let maxNum = 0;
      const xAxisData = dataList.map((val) => {
        return val.hourNode + '-' + (val.hourNode + 1)
      });
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "16%", // 等价于 y: '16%'
          left: "5%",
          right: "10%",
          bottom: "5%",
          containLabel: true,
        },
        icon: "circle",
        xAxis: {
          name: '时段/时',
          type: "category",
          data: xAxisData,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          nameTextStyle: {
            fontSize: sizeHNum * 1.2,
            color: "#fff",
          },
          axisTick: {
            //刻度线
            show: false,
          },
          axisLabel: {
            show: true,
            interval: 0,
            textStyle: {
              color: "#fff",
            },
          },
        },

        yAxis: [{
          name: '小时数',
          type: "value",
          position: 'left',
          axisLine: {
            lineStyle: {
              color: "#575E71",
              fontSize: sizeHNum * 1.2,
            },
          },
          nameTextStyle: {
            fontSize: sizeHNum * 1.2,
            color: "#fff",
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value;
            },
            textStyle: {
              color: "#fff",
              fontSize: sizeHNum * 1.2,
            },
          },
        },
        ],
        series: [
          {
            type: "bar",
            barWidth: sizeHNum * 1.2,
            barGap: sizeHNum * 2,
            label: {
              show: false,
              position: "top",
              formatter: (params) => {
                let str = params.value +'万/'+ params.data.typeRatio + '%';
                return str;
              },
              fontSize: sizeHNum * 1.6,
              color: "#fff",
              offset: [0, -(sizeHNum * 0.4)],
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "rgba(152, 224, 95, 1)" },
                { offset: 0, color: "rgba(13, 227, 172, 1)" },
              ]),
            },
            data: dataList.map((val) => {return{...val,value: val.hourNumber}}),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.screen-user {
  position: relative;
  height: 87%;
  padding: 1%;
  .quantity-box {
    position: absolute;
    top: -9.5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    div {
      span {
        font-size: 0.48rem;
        font-family: "ZhanKuQingKeHuangYouTi";
        background-image: -webkit-linear-gradient(
          bottom,
          rgba(22, 142, 245, 1),
          rgba(255, 255, 255, 1)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-bg {
      padding: 0 0.16rem;
      background-image: url("../../../../assets/images/bigScreen/home/quantity.png");
      background-size: 100% 100%;
    }
  }

  .line-label {
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    justify-content: space-around;
    &-view {
      display: flex;
      align-items: center;
      margin-left: 10px;
      &-circle {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 8px;
        border-radius: 50%;
        background-color: rgba(150, 245, 248, 1);
      }
      &-text {
        font-size: 0.16rem;
        color: #ffffff;
      }
      &-circle1 {
        background-color: rgba(255, 253, 55, 1);
      }
    }
  }
}
</style>