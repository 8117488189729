<template>
  <div class="model-box">
    <div class="activity" ref="activity">
      <div class="activity-item" v-for="item,index in activityList" :key="index">
        <div class="activity-item-top">
          <div class="activity-item-top-left">{{ item.riskType }}：</div>
          <div class="activity-item-top-right">
            <Chart style="height: 100%" :option="item.gaugeOptions"></Chart>
          </div>
        </div>
        <div class="activity-item-down">{{ item.remark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  watch: {
    options: {
      handler(v) {
        this.activityList = v.map(val=> {
          return {
            ...val,
            gaugeOptions: this.initChart(this.colorList[val.riskType], val.fraction)
          }
        })
      },
      deep: true,
      immediate: false
    }
  },
  data() {
    return {
      colorList: {
        '安全措施评估': 'rgba(237, 72, 72, 1)',
        '维修保养评估': 'rgba(1, 223, 233, 1)'
      },
      activityList: [
        {
          label: "设备性能评估：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {}
        },
        {
          label: "操作人员评估：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
        },
        {
          label: "使用环境评估：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
        },
        {
          label: "安全措施评估：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
          color: 'rgba(237, 72, 72, 1)'
        },
        {
          label: "维修保养评估：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
          color: 'rgba(1, 223, 233, 1)'
        },
        {
          label: "定期检查：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
        },
        {
          label: "操作培训：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
        },
        {
          label: "安全教育：",
          msg: "该挖掘机性能稳定，使用年限较短，机械状况良好",
          gaugeOptions: {},
        },
      ],
      gaugeOptions: {},
    };
  },
  created() {
    this.$nextTick(() => {
    });
  },
  methods: {
    getSize(res, type) {
      const clientWidth = this.$refs["activity"].offsetWidth * 0.5;
      const clientHeight = this.$refs["activity"].offsetHeight * 0.25;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initChart(color='rgba(11, 209, 100, 1)', value) {
      const sizeHNum = this.getSize(0.3, 2);
      // var value = 5.6;
      var pointerColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgba(135, 135, 165, 1)",
        },
        {
          offset: 1,
          color: "rgba(135, 135, 165, 1)",
        },
      ]);
      // var color2 = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
      //   {
      //     offset: 0,
      //     color: color,
      //   },
      //   {
      //     offset: 1,
      //     color: color,
      //   },
      // ]);

      return {
        series: [
          {
            name: "已到人数",
            type: "gauge",
            radius: "80%",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            title: {
              show: false,
            },
            detail: {
              color: color,
              show: true,
              fontFamily: '"Microsoft Yahei","微软雅黑"',
              fontSize: sizeHNum * 5,
              offsetCenter: [0, "80%"],
              formatter(param) {
                return `${value}{sty1|分}`;
              },
              rich: {
                sty1: {
                  color: "#fff",
                  fontSize: sizeHNum * 2.4,
                },
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: sizeHNum * 1.5,
                color: [
                  [value / 100, color],
                  [1, "rgba(225,225,225,0.4)"],
                ],
                // shadowColor: 'rgba(0,138,255,0.35)',
                // shadowBlur: 5,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              itemStyle: {
                color: pointerColor,
              },

              // show:false,
              width: sizeHNum * 0.8,
              length: sizeHNum * 2.8,
            },
            splitLine: {
              show: false,
            },
            data: [
              {
                value: value,
                name: "综合价值得分",
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 95%;
  padding: 2%;
  .activity {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    &-item {
      width: 50%;
      height: 25%;
      color: #fff;
      padding: 0 3%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../../../../assets/images/bigScreen/equipmentInfoRecord/riskIcon.png");
      &-top {
        display: flex;
        align-items: center;
        height: 55%;
        &-left {
          width: 48%;
          font-size: 0.14rem;
          padding-left: 8%;
        }
        &-right {
          flex: 1;
          height: 80%;
        }
      }
      &-down {
        height: 40%;
        font-size: 0.16rem;
      }
    }
  }
}
</style>