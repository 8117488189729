<template>
  <div class="model-box">
    <div class="basic-s">
      <img class="basic-s-icon" src="../../../../assets/images/bigScreen/equipmentInfoRecord/moneyIcon.png" alt="" srcset="">
      <div class="basic-s-content">
        <div class="basic-s-content-num">{{ options.policyAmount || '--' }}</div>
        <div class="basic-s-content-text">{{ options.policyType || '--' }}</div>
      </div>
    </div>
    <div class="basic-box">
      <div class="basic-box-item">投保日期：{{ options.insureData || '--' }}</div>
      <div class="basic-box-item">保险公司：{{ options.policyCompany || '--' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: ()=>{}
    }
  },
}
</script>

<style lang="scss" scoped>
.model-box {
  height: 79%;
  font-size: 0.14rem;
  color: #FFFFFF;
  .basic-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 4%;
    &-item {
      width: 49%;
      margin-bottom: 0.14rem;
    }
  }
  .basic-s {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.2rem;
    &-icon {
      width: 0.7rem;
      height: 0.78rem;
      margin-right: 0.08rem;
    }
    &-content {
      &-num {
        font-size: 0.2rem;
        color: #EAF1FD;
        font-family: 'ZhanKuQingKeHuangYouTi';
        margin-top: 0.28rem;
        margin-bottom: 0.06rem;
      }
      &-text {
        font-size: 0.14rem;
        color: #06EFF2;
      }
    }
  }
}
</style>