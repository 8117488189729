<template>
  <div class="model-box">
      <div class="car-name">
        {{ options.workTime || '--' }}
      </div>
    <div class="car-box">
      <img
        class="car-icon"
        src="../../../../assets/images/bigScreen/equipmentInfoRecord/carIcon.png"
        alt=""
        srcset=""
      >
      <div class="top-left">
        <div class="top-left-content">
          <div class="top-left-content-text">设备运行工时（小时）</div>
          <div class="top-left-content-num">{{ options.workTime || '--' }}</div>
        </div>
      </div>
      <div class="top-right">
        <div class="top-left-content" style="margin-left:0.4rem">
          <div class="top-left-content-text">保险保障天数（天）</div>
          <div class="top-left-content-num">{{ options.guaranteeDays || '--' }}</div>
        </div>
      </div>
      <div class="bottom-left">
        <div class="top-left-content">
          <div class="top-left-content-text">安全监控天数（天）</div>
          <div class="top-left-content-num">{{ options.monitoringDays || '--' }}</div>
        </div>
      </div>
      <div class="bottom-right">
        <div class="bottom-right-content">
          <div class="bottom-right-content-t">机主信息</div>
          <div class="bottom-right-content-i">{{ options.ownerName || '--' }}
            <img class="bottom-right-content-i-icon" v-if="options.deviceType == 1" src="../../../../assets/images/bigScreen/equipmentInfoRecord/yzIcon.png" alt="" srcset="">
            <img class="bottom-right-content-i-icon" v-else-if="options.deviceType == 3" src="../../../../assets/images/bigScreen/equipmentInfoRecord/fxIcon.png" alt="" srcset="">
            <img class="bottom-right-content-i-icon" v-else src="../../../../assets/images/bigScreen/equipmentInfoRecord/cgIcon.png" alt="" srcset="">
          </div>
          <div class="bottom-right-content-i">{{ options.ownerPhone || '--' }}</div>
        </div>
      </div>
      <div class="car-type">类型：{{ options.deviceType || '--' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: ()=>{}
    }
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  position: relative;
  .car-name {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 0.22rem;
    color: #06EFF2;
  }
  .car-icon {
    width: 100%;
  }
  .car-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48%;
    transform: translate(-50%, -50%);
    .car-type {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      font-size: 0.16rem;
      color: #ADEAFF;
    }
  }
  .top-left {
    width: 2.29rem;
    height: 1.15rem;
    position: absolute;
    top: 9%;
    left: 9%;
    transform: translate(-100%, -100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/bigScreen/equipmentInfoRecord/equipmentInfo.png');
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 1.9rem;
      height: 0.7rem;
      color: #ADEAFF;
      &-text {
        text-align: center;
        font-size: 0.16rem;
      }
      &-num {
        font-size: 0.24rem;
        text-align: center;
        font-family: 'ZhanKuQingKeHuangYouTi';
      }
    }
  }
  .top-right {
    width: 2.29rem;
    height: 1.15rem;
    position: absolute;
    top: 7%;
    right: 7%;
    transform: translate(100%, -100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/bigScreen/equipmentInfoRecord/insuranceInfo.png');
  }
  .bottom-left {
    width: 2.09rem;
    height: 0.74rem;
    position: absolute;
    bottom: 7%;
    left: 3.5%;
    transform: translate(-100%, -100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/bigScreen/equipmentInfoRecord/monitorIcon.png');
  }
  .bottom-right {
    width: 2.2rem;
    height: 1.17rem;
    position: absolute;
    bottom: 16%;
    right: 5%;
    transform: translate(100%, 0%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/images/bigScreen/equipmentInfoRecord/machineIcon.png');
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 1.9rem;
      height: 1.1rem;
      margin-left: 0.3rem;
      color: #ADEAFF;
      &-t {
        text-align: center;
        font-size: 0.16rem;
      }
      &-i {
        display: flex;
        align-items: center;
        font-size: 0.18rem;
        padding-left: 0.2rem;
        &-icon {
          width: 0.93rem;
          height: 0.32rem;
          margin-left: 0.1rem;
        }
      }
    }
  }
}
</style>